import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import { getToken, getTokenInformation } from '@csdm-frontend/csdm-idp-frontend'

import asyncComponent from './components/AsyncComponent'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import NotFound from './containers/notfound/NotFound'
import LoginView from './containers/loginview'
import LogoutView from './containers/LogoutView'
import { RecoveryView } from './containers/recoveryview'
import Reset2faView from './containers/reset2faview'
import MyUserAccount from './containers/myuseraccount'
import UserManagementView from './containers/usermanagementview'

import { SitesRedirect, PlayersRedirect, DevicesRedirect, AppsRedirect } from './scenes/Inventory/redirect'

const CreateTicket = asyncComponent(() => import('./modules/support/tickets/createTicket'))
const Home = asyncComponent(() => import('./components/Home'))
const GlobalOverview = asyncComponent(() => import('./scenes/Support/SiteList'))
const KnowledgeBase = asyncComponent(() => import('./containers/KnowledgeBase'))

const SiteView = asyncComponent(() => import('./scenes/Support/SiteDetail'))
const SiteH2Dashboard = asyncComponent(() => import('./scenes/Support/SiteH2Dashboard'))

const BrokerConfigView = asyncComponent(() => import('./scenes/Support/BrokerConfigView'))

const EventLogs = asyncComponent(() => import('./scenes/Reporting/EventLogs'))
const EnozzleWarningLogs = asyncComponent(() => import('./scenes/Reporting/EnozzleWarningLogs'))

const EnozzleCreativeList = asyncComponent(() => import('./scenes/Enozzle/scenes/CreativeList'))
const EnozzleCreativeCreate  = asyncComponent(() => import('./scenes/Enozzle/scenes/CreativeCreate'))
const EnozzleFirmwareList  = asyncComponent(() => import('./scenes/Enozzle/scenes/FirmwareList'))
const EnozzleFirmwareCreate  = asyncComponent(() => import('./scenes/Enozzle/scenes/FirmwareCreate'))
const EnozzlePackageList  = asyncComponent(() => import('./scenes/Enozzle/scenes/PackageList'))
const EnozzlePackageCreate  = asyncComponent(() => import('./scenes/Enozzle/scenes/PackageCreate'))
const EnozzleScheduleList = asyncComponent(() => import('./scenes/Enozzle/scenes/ScheduleList'))
const EnozzleScheduleEdit = asyncComponent(() => import('./scenes/Enozzle/scenes/ScheduleEdit'))
const EnozzleSiteList  = asyncComponent(() => import('./scenes/Enozzle/scenes/Sites'))
const EnozzleTaskList  = asyncComponent(() => import('./scenes/Enozzle/scenes/TaskList'))
const EnozzleTaskEdit  = asyncComponent(() => import('./scenes/Enozzle/scenes/TaskEdit'))
const EnozzleLivedata  = asyncComponent(() => import('./scenes/Enozzle/scenes/Livedata'))
const EnozzleRangeSettings = asyncComponent(() => import('./scenes/Enozzle/scenes/RangeSettings'))
const EnozzleNrsIpAddressList = asyncComponent(() => import('./scenes/Enozzle/scenes/NrsIpAddressList'))
const EnozzleNrsIpAddressCreate = asyncComponent(() => import('./scenes/Enozzle/scenes/NrsIpAddressCreate'))

const AsyncInventorySites = asyncComponent(() => import("./scenes/Inventory/InventorySites"))
const AsyncInventoryAddSite = asyncComponent(() => import("./scenes/Inventory/InventoryAddSite"))
const AsyncInventoryUpdateSite = asyncComponent(() => import("./scenes/Inventory/InventoryUpdateSite"))

const AsyncInventoryPlayers = asyncComponent(() => import("./scenes/Inventory/InventoryPlayers"))
const AsyncInventoryAddPLayer = asyncComponent(() => import("./scenes/Inventory/InventoryAddPlayer"))
const AsyncInventoryClonePlayer = asyncComponent(() => import("./scenes/Inventory/InventoryClonePlayer"))
const AsyncInventoryUpdatePlayer = asyncComponent(() => import("./scenes/Inventory/InventoryUpdatePlayer"))
const AsyncInventoryViewPlayer = asyncComponent(() => import("./scenes/Inventory/InventoryViewPlayer"))
const AsyncInventoryApps = asyncComponent(() => import("./scenes/Inventory/InventoryApps"))
const AsyncInventoryAddApp = asyncComponent(() => import("./scenes/Inventory/InventoryAddApp"))
const AsyncInventoryDevices = asyncComponent(() => import("./scenes/Inventory/InventoryDevices"))
const AsyncInventoryAddDevice = asyncComponent(() => import("./scenes/Inventory/InventoryAddDevice"))
const AsyncInventorySettings = asyncComponent(() => import("./scenes/Inventory/SettingsView"))
const AsyncInventoryBulkUpload = asyncComponent(() => import("./scenes/Inventory/BulkUploadView"))
const AsyncInventoryBulkUpdate = asyncComponent(() => import("./scenes/Inventory/BulkUpdate"))

const WebplayerScreenlayouts = asyncComponent(() => import('./scenes/WebplayerView/ScreenLayoutsView'));
const WebplayerNewScreenlayouts = asyncComponent(() => import('./scenes/WebplayerView/NewScreenLayoutView'));
const WebplayerEditScreenlayouts = asyncComponent(() => import('./scenes/WebplayerView/EditScreenLayoutView'));
const WebplayerReassignPlayerToScreenLayout = asyncComponent(() => import('./scenes/WebplayerView/ReassignPlayerToScreenLayoutView'));

// const AsyncSiteFacilities = asyncComponent(() => import("./scenes/Facilities"))

const AsyncFuelTypes = asyncComponent(() => import("./scenes/Configuration/FuelTypes"))
const AsyncCisComponents = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/CisComponents"))
const AsyncCisCampaigns = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/Campaigns"))
const AsyncCisCampaignsCrud = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/Campaigns/CampaignCrud"))
const AsyncCisCampaignEditV2 = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/Campaigns/V2/CampaignEdit"))
const AsyncCisCampaignsView = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/Campaigns/CampaignView"))
const AsyncCisLineItemCreation = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/Campaigns/CrudCampaignLineItem"))
const AsyncCisCreatives = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/CisCreative"))
const AsyncCisCreativesNew = asyncComponent(() => import("./scenes/Multichannel/CisWebplayer/CisCreative/components/CreateCreative"))

const VehicleDashboard = asyncComponent(() => import('./scenes/Data/VehicleDashboard'))


export const SITES_REDIRECT = { path: '/sites', roles: ['ADMIN', 'ENOZZLE'] }
export const PLAYERS_REDIRECT = { path: '/players', roles: ['ADMIN', 'ENOZZLE'] }
export const DEVICES_REDIRECT = { path: '/devices', roles: ['ADMIN', 'ENOZZLE'] }
export const APPS_REDIRECT = { path: '/apps', roles: ['ADMIN', 'ENOZZLE'] }


export const ROUTE_ENOZZLE_CREATIVE_INDEX = { path: '/enozzle/creative/index', match: new RegExp('^/enozzle/creative/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_CREATIVE_CREATE = { path: '/enozzle/creative/create', match: new RegExp('^/enozzle/creative/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_FIRMWARE_CREATE = { path: '/enozzle/firmware/create', match: new RegExp('^/enozzle/firmware/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_FIRMWARE_INDEX = { path: '/enozzle/firmware/index', match: new RegExp('^/enozzle/firmware/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_PACKAGE_CREATE = { path: '/enozzle/package/create', match: new RegExp('^/enozzle/package/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_PACKAGE_INDEX = { path: '/enozzle/package/index', match: new RegExp('^/enozzle/package/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_SCHEDULE_INDEX = { path: '/enozzle/schedule/index', match: new RegExp('^/enozzle/schedule/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_SCHEDULE_CREATE = { path: '/enozzle/schedule/create', match: new RegExp('^/enozzle/schedule/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_SCHEDULE_UPDATE = { path: '/enozzle/schedule/update/:id', match: new RegExp('^/enozzle/task/schedule/([^/]+)/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_SITE_INDEX = { path: '/enozzle/site/index', match: new RegExp('^/enozzle/site/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_TASK_CREATE = { path: '/enozzle/task/create', match: new RegExp('^/enozzle/task/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_TASK_INDEX = { path: '/enozzle/task/index', match: new RegExp('^/enozzle/task/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_TASK_UPDATE = { path: '/enozzle/task/update/:id', match: new RegExp('^/enozzle/task/update/([^/]+)/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_LIVEDATA = { path: '/enozzle/livedata/index', match: new RegExp('^/enozzle/livedata/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_NRS_IP_ADDRESS_INDEX = { path: '/enozzle/nrsipaddress/index', match: new RegExp('^/enozzle/nrsipaddress/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_ENOZZLE_NRS_IP_ADDRESS_CREATE = { path: '/enozzle/nrsipaddress/create', match: new RegExp('^/enozzle/nrsipaddress/create/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_HOME = { path: '/', match: new RegExp('^/$', 'i'), roles: ['ADMIN'] }
export const ROUTE_LOGIN = { path: '/login', match: new RegExp('^/login/?', 'i'), roles: [] }
export const ROUTE_LOGOUT = { path: '/logout', match: new RegExp('^/logout/?', 'i'), roles: [] }
export const ROUTE_RECOVERY = { path: '/recovery/token/:index', match: new RegExp('^/recovery/token/([^/]+)/?', 'i'), roles: [] }
export const ROUTE_RESET2FA = { path: '/reset2fa/token/:index', match: new RegExp('^/reset2fa/token/([^/]+)/?', 'i'), roles: [] }
export const ROUTE_SUPPORT_KNOWLEDGEBASE = { path: '/support/knowledgeBase', match: new RegExp('^/support/knowledgeBase/?', 'i'), roles: ['ADMIN', 'KNOWLEDGE'] }
export const ROUTE_SUPPORT_SITE_INDEX = { path: '/support/site/index', match: new RegExp('^/support/site/index/?', 'i'), roles: ['ADMIN', 'MONITOR', 'EXTERNAL_PCA'] }
export const ROUTE_SUPPORT_SITE_VIEW = { path: '/support/site/view/:index', match: new RegExp('^/support/site/view/([^/]+)/?', 'i'), roles: ['ADMIN', 'MONITOR', 'EXTERNAL_PCA'] }
export const ROUTE_SUPPORT_SITE_H2_DASHBOARD = { path: '/support/site/H2dashboard/:dashboard/:siteCode', match: new RegExp('^/support/site/H2dashboard/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_SUPPORT_ENOZZLE_RANGE_INDEX = { path: '/support/enozzle/range/index', match: new RegExp('^/support/enozzle/range/index/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }
export const ROUTE_SUPPORT_TICKET_CREATE = { path: '/support/ticket/create', match: new RegExp('^/support/ticket/create/?', 'i'), roles: ['ADMIN', 'MORE_HELP'] }
export const ROUTE_USER_MANAGEMENT = { path: '/usermanagement', match: new RegExp('^/usermanagement/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_USER_MYINFO = { path: '/usermyinfoview', match: new RegExp('^/usermyinfoview/?', 'i'), roles: ['ADMIN'] }


export const ROUTE_REPORTING_EVENT_LOGS = { path: '/reporting/eventlogs', match: new RegExp('^/reporting/eventlogs/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_REPORTING_ENOZZLE_WARNING_LOGS = { path: '/reporting/enozzlewarninglogs', match: new RegExp('^/reporting/enozzlewarninglogs/?', 'i'), roles: ['ADMIN', 'ENOZZLE'] }


export const ROUTE_SUPPORT_BROKER_CONFIG = { path: '/support/broker/config/:brokerId', match: new RegExp('^/support/broker/config/([^/]+)/?', 'i'), roles: ['ADMIN'] }

export const ROUTE_INVENTORY_APPS = { path: '/inventory-apps', match: new RegExp('^/inventory-apps/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_ADD_APP = { path: '/apps/new', match: new RegExp('^/apps/new', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_UPDATE_APP = { path: '/apps/:index/edit', match: new RegExp('^/apps/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_DEVICES = { path: '/inventory-devices', match: new RegExp('^/inventory-devices/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_ADD_DEVICE = { path: '/devices/new', match: new RegExp('^/devices/new', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_UPDATE_DEVICE = { path: '/devices/:index/edit', match: new RegExp('^/devices/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_ITEMS = { path: '/inventory-items', match: new RegExp('^/inventory-items/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_PLAYERS = { path: '/inventory-players', match: new RegExp('^/inventory-players/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_PLAYERS_INDEX = { path: '/inventory-players/:index', match: new RegExp('^/inventory-players/([^/]+)/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_ADD_PLAYER = { path: '/players/new', match: new RegExp('^/players/new', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_ADD_PLAYER_INDEX = { path: '/players/new/:index', match: new RegExp('^/players/new/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_UPDATE_PLAYER_INDEX = { path: '/players/:index/edit', match: new RegExp('^/players/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_VIEW_PLAYER_INDEX = { path: '/players/:index/details', match: new RegExp('^/players/([^/]+)/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_CLONE_PLAYER_INDEX = { path: '/players/:index/clone', match: new RegExp('^/players/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_SITES = { path: '/inventory-sites', match: new RegExp('^/inventory-sites/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_INVENTORY_ADD_SITE = { path: '/sites/new', match: new RegExp('^/sites/new/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_UPDATE_SITE_INDEX = { path: '/sites/:index', match: new RegExp('^/sites/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_BULK_UPLOAD = { path: '/players/bulk/upload', match: new RegExp('^/players/bulk/upload?', 'i'), roles: ['ADMIN'] }
export const ROUTE_INVENTORY_BULK_UPDATE = { path: '/players/bulk/update', match: new RegExp('^/players/bulk/update/?', 'i'), roles: ['ADMIN'] }

export const WEBPLAYER_SCREEN_LAYOUTS_READ = { path: '/screenlayouts', match: new RegExp('^/screenlayouts/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const WEBPLAYER_SCREEN_LAYOUTS_CREATE = { path: '/newscreenlayout', match: new RegExp('^/newscreenlayout/?', 'i'), roles: ['ADMIN'] }
export const WEBPLAYER_SCREEN_LAYOUTS_UPDATE = { path: '/editscreenlayout/:index', match: new RegExp('^/editscreenlayout/([^/]+)/?', 'i'), roles: ['ADMIN'] }
export const WEBPLAYER_SCREEN_LAYOUTS_ASSIGN = { path: '/reassignplayertoscreenLayout/:index', match: new RegExp('^/reassignplayertoscreenLayout/([^/]+)/?', 'i'), roles: ['ADMIN'] }

// export const ROUTE_SITE_FACILITIES = { path: '/site-facilities', match: new RegExp('^/site-facilities/?', 'i'), roles: ['ADMIN'] }

export const ROUTE_FUEL_TYPES = { path: '/configuration/fuel-types', match: new RegExp('^/configuration/fuel-types/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_CIS_COMPONENTS = { path: '/multichannel/cis-webplayer/component-settings', match: new RegExp('^/multichannel/cis-webplayer/component-settings/?', 'i'), roles: ['ADMIN'] }
export const ROUTE_CIS_CAMPAIGNS = { path: '/loops', match: new RegExp('^/loops/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CAMPAIGNS_NEW = { path: '/loops/new', match: new RegExp('^/loops/new/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CAMPAIGNS_VIEW = { path: '/loops/:loopId/view', match: new RegExp('^/loops/([^/]+)/view/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CAMPAIGNS_UPDATE = { path: '/loops/:loopId/update', match: new RegExp('^/loops/([^/]+)/update/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CAMPAIGNS_V2_EDIT = { path: '/loops/v2/:loopId/edit', match: new RegExp('^/loops/([^/]+)/edit/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CAMPAIGNS_V2_CREATE = { path: '/loops/v2/create', match: new RegExp('^/loops/([^/]+)/create/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_LINE_ITEM_NEW = { path: '/loops/:loopId/slot/new', match: new RegExp('^/loops/([^/]+)/slot/new/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_LINE_ITEM_UPDATE = { path: '/loops/:loopId/slot/:slotId/update', match: new RegExp('^/loops/([^/]+)/slot/([^/]+)/update/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CREATIVES = {path: '/slots', match: new RegExp('^/slots/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }
export const ROUTE_CIS_CREATIVES_NEW = {path: '/slots/new', match: new RegExp('^/slots/new/?', 'i'), roles: ['ADMIN', 'EXTERNAL_PCA'] }

export const ROUTE_DATA_VEHICLE_DASHBOARD = { path: '/data/vehicledashboard', match: new RegExp('^/data/vehicledashboard/?', 'i'), roles: ['ADMIN'] }

const secureRoutes = [
  ROUTE_ENOZZLE_CREATIVE_INDEX,
  ROUTE_ENOZZLE_FIRMWARE_INDEX,
  ROUTE_ENOZZLE_PACKAGE_INDEX,
  ROUTE_ENOZZLE_SCHEDULE_INDEX,
  ROUTE_ENOZZLE_SITE_INDEX,
  ROUTE_ENOZZLE_TASK_INDEX,
  ROUTE_ENOZZLE_LIVEDATA,
  ROUTE_HOME,
  ROUTE_SUPPORT_KNOWLEDGEBASE,
  ROUTE_SUPPORT_SITE_INDEX,
  ROUTE_SUPPORT_SITE_VIEW,
  ROUTE_SUPPORT_SITE_H2_DASHBOARD,
  ROUTE_SUPPORT_ENOZZLE_RANGE_INDEX,
  ROUTE_SUPPORT_TICKET_CREATE,
  ROUTE_SUPPORT_BROKER_CONFIG,
  ROUTE_REPORTING_EVENT_LOGS,
  ROUTE_REPORTING_ENOZZLE_WARNING_LOGS,
  ROUTE_ENOZZLE_FIRMWARE_CREATE,
  ROUTE_ENOZZLE_CREATIVE_CREATE,
  ROUTE_ENOZZLE_NRS_IP_ADDRESS_INDEX,
  ROUTE_ENOZZLE_NRS_IP_ADDRESS_CREATE,
  ROUTE_USER_MANAGEMENT,
  ROUTE_USER_MYINFO,
  ROUTE_INVENTORY_APPS,
  ROUTE_INVENTORY_ADD_APP,
  ROUTE_INVENTORY_UPDATE_APP,
  ROUTE_INVENTORY_DEVICES,
  ROUTE_INVENTORY_ADD_DEVICE,
  ROUTE_INVENTORY_UPDATE_DEVICE,
  ROUTE_INVENTORY_ITEMS,
  ROUTE_INVENTORY_PLAYERS,
  ROUTE_INVENTORY_PLAYERS_INDEX,
  ROUTE_INVENTORY_ADD_PLAYER,
  ROUTE_INVENTORY_ADD_PLAYER_INDEX,
  ROUTE_INVENTORY_UPDATE_PLAYER_INDEX,
  ROUTE_INVENTORY_VIEW_PLAYER_INDEX,
  ROUTE_INVENTORY_CLONE_PLAYER_INDEX,
  ROUTE_INVENTORY_SITES,
  ROUTE_INVENTORY_ADD_SITE,
  ROUTE_INVENTORY_BULK_UPLOAD,
  ROUTE_INVENTORY_BULK_UPDATE,
  // ROUTE_SITE_FACILITIES,
  WEBPLAYER_SCREEN_LAYOUTS_READ,
  WEBPLAYER_SCREEN_LAYOUTS_CREATE,
  WEBPLAYER_SCREEN_LAYOUTS_UPDATE,
  WEBPLAYER_SCREEN_LAYOUTS_ASSIGN,
  ROUTE_ENOZZLE_PACKAGE_CREATE,
  ROUTE_ENOZZLE_SCHEDULE_CREATE,
  ROUTE_ENOZZLE_TASK_CREATE,
  ROUTE_ENOZZLE_SCHEDULE_UPDATE,
  SITES_REDIRECT,
  ROUTE_ENOZZLE_TASK_UPDATE,
  ROUTE_FUEL_TYPES,
  ROUTE_CIS_COMPONENTS,
  PLAYERS_REDIRECT,
  ROUTE_CIS_CAMPAIGNS,
  ROUTE_CIS_CAMPAIGNS_NEW,
  ROUTE_CIS_CAMPAIGNS_UPDATE,
  ROUTE_CIS_CAMPAIGNS_V2_EDIT,
  ROUTE_CIS_CAMPAIGNS_V2_CREATE,
  ROUTE_CIS_CAMPAIGNS_VIEW,
  ROUTE_CIS_LINE_ITEM_NEW,
  ROUTE_CIS_LINE_ITEM_UPDATE,
  ROUTE_CIS_CREATIVES,
  ROUTE_CIS_CREATIVES_NEW,
  ROUTE_DATA_VEHICLE_DASHBOARD,
]
let userRoles
export const setRoles = (roles) => {
  userRoles = roles
}

export const getPath = route => route.path

export const getLink = (route, params={}) => {
  let link = route.path
  Object.keys(params).forEach(key => {
    link = link.replace(`:${key}`, params[key])
  })
  return link
}

export const routeAllowed = (route) => {
  const keys = Object.keys(secureRoutes)
  const allowed = keys.find((key) => {
    const secureRoute = secureRoutes[key]
    if (route.match(secureRoute.match)) {
      const roleFound = secureRoute.roles.find(role => userRoles && userRoles.includes(role))
      if (roleFound) {
        return true
      }
      return false
    }
    return false
  })
  return allowed
}

export const getRoles = () => {
  const tokenInfo = getTokenInformation()
  console.log(tokenInfo)
  return tokenInfo
}

const isUserAuthenticated = () => {
  if (getToken() === null) {
    return false
  } else {
    const tokenInfo = getTokenInformation()
    return new Date(`${tokenInfo.exp}000`) > new Date()
  }
}

const routes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute path={getPath(ROUTE_LOGIN)} exact component={LoginView} props={childProps} />
    <UnauthenticatedRoute path={getPath(ROUTE_RECOVERY)} component={RecoveryView} props={childProps} />
    <UnauthenticatedRoute path={getPath(ROUTE_RESET2FA)} component={Reset2faView} props={childProps} />
    { /* User must be authenticated before entering following components */ }
    {(routeAllowed(ROUTE_ENOZZLE_CREATIVE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_CREATIVE_INDEX)} component={EnozzleCreativeList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_CREATIVE_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_CREATIVE_CREATE)} component={EnozzleCreativeCreate} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_FIRMWARE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_FIRMWARE_INDEX)} component={EnozzleFirmwareList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_FIRMWARE_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_FIRMWARE_CREATE)} component={EnozzleFirmwareCreate} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_PACKAGE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_PACKAGE_INDEX)} component={EnozzlePackageList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_PACKAGE_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_PACKAGE_CREATE)} component={EnozzlePackageCreate} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_SCHEDULE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_SCHEDULE_INDEX)} component={EnozzleScheduleList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_SCHEDULE_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_SCHEDULE_CREATE)} component={EnozzleScheduleEdit} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_SCHEDULE_UPDATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_SCHEDULE_UPDATE)} component={EnozzleScheduleEdit} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_SITE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_SITE_INDEX)} component={EnozzleSiteList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_TASK_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_TASK_INDEX)} component={EnozzleTaskList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_TASK_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_TASK_CREATE)} component={EnozzleTaskEdit} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_TASK_UPDATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_TASK_UPDATE)} component={EnozzleTaskEdit} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_LIVEDATA.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_LIVEDATA)} component={EnozzleLivedata} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_NRS_IP_ADDRESS_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_NRS_IP_ADDRESS_INDEX)} component={EnozzleNrsIpAddressList} props={childProps} />}
    {(routeAllowed(ROUTE_ENOZZLE_NRS_IP_ADDRESS_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_ENOZZLE_NRS_IP_ADDRESS_CREATE)} component={EnozzleNrsIpAddressCreate} props={childProps} />}
    <AuthenticatedRoute path={getPath(ROUTE_HOME)} exact component={Home} props={childProps} />
    <AuthenticatedRoute path={getPath(ROUTE_LOGOUT)} component={LogoutView} props={childProps} />
    <AuthenticatedRoute path={getPath(ROUTE_USER_MYINFO)} component={MyUserAccount} props={childProps} />
    <AuthenticatedRoute path={getPath(ROUTE_USER_MANAGEMENT)} component={UserManagementView} props={childProps} />
    {(routeAllowed(ROUTE_SUPPORT_KNOWLEDGEBASE.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_KNOWLEDGEBASE)} component={KnowledgeBase} props={childProps} />}
    {(routeAllowed(ROUTE_SUPPORT_SITE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_SITE_INDEX)} component={GlobalOverview} props={childProps} />}
    {(routeAllowed(ROUTE_SUPPORT_SITE_VIEW.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_SITE_VIEW)} component={SiteView} props={childProps} />}
    {(routeAllowed(ROUTE_SUPPORT_SITE_H2_DASHBOARD.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_SITE_H2_DASHBOARD)} component={SiteH2Dashboard} props={childProps} />}

    {(routeAllowed(ROUTE_REPORTING_EVENT_LOGS.path)) && <AuthenticatedRoute path={getPath(ROUTE_REPORTING_EVENT_LOGS)} component={EventLogs} props={childProps} />}
    {(routeAllowed(ROUTE_REPORTING_ENOZZLE_WARNING_LOGS.path)) && <AuthenticatedRoute path={getPath(ROUTE_REPORTING_ENOZZLE_WARNING_LOGS)} component={EnozzleWarningLogs} props={childProps} />}


    {(routeAllowed(ROUTE_SUPPORT_BROKER_CONFIG.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_BROKER_CONFIG)} component={BrokerConfigView} props={childProps} />}

    {(routeAllowed(ROUTE_INVENTORY_APPS.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_APPS)} component={AsyncInventoryApps} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ADD_APP.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ADD_APP)} exact component={AsyncInventoryAddApp} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_UPDATE_APP.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_UPDATE_APP)} exact component={AsyncInventoryAddApp} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ADD_DEVICE.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ADD_DEVICE)} exact component={AsyncInventoryAddDevice} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_UPDATE_DEVICE.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_UPDATE_DEVICE)} exact component={AsyncInventoryAddDevice} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_DEVICES.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_DEVICES)} component={AsyncInventoryDevices} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ITEMS.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ITEMS)} component={AsyncInventorySettings} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_PLAYERS.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_PLAYERS)} exact component={AsyncInventoryPlayers} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_PLAYERS_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_PLAYERS_INDEX)} component={AsyncInventoryPlayers} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ADD_PLAYER.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ADD_PLAYER)} exact component={AsyncInventoryAddPLayer} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ADD_PLAYER_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ADD_PLAYER_INDEX)} component={AsyncInventoryAddPLayer} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_UPDATE_PLAYER_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_UPDATE_PLAYER_INDEX)} component={AsyncInventoryUpdatePlayer} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_VIEW_PLAYER_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_VIEW_PLAYER_INDEX)} component={AsyncInventoryViewPlayer} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_CLONE_PLAYER_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_CLONE_PLAYER_INDEX)} component={AsyncInventoryClonePlayer} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_SITES.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_SITES)} component={AsyncInventorySites} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_ADD_SITE.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_ADD_SITE)} component={AsyncInventoryAddSite} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_UPDATE_SITE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_UPDATE_SITE_INDEX)} component={AsyncInventoryUpdateSite} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_BULK_UPLOAD.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_BULK_UPLOAD)} component={AsyncInventoryBulkUpload} props={childProps} />}
    {(routeAllowed(ROUTE_INVENTORY_BULK_UPDATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_INVENTORY_BULK_UPDATE)} component={AsyncInventoryBulkUpdate} props={childProps} />}

    {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_READ.path)) && <AuthenticatedRoute path={getPath(WEBPLAYER_SCREEN_LAYOUTS_READ)} component={WebplayerScreenlayouts} props={childProps} />}
    {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_CREATE.path)) && <AuthenticatedRoute path={getPath(WEBPLAYER_SCREEN_LAYOUTS_CREATE)} component={WebplayerNewScreenlayouts} props={childProps} />}
    {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_UPDATE.path)) && <AuthenticatedRoute path={getPath(WEBPLAYER_SCREEN_LAYOUTS_UPDATE)} component={WebplayerEditScreenlayouts} props={childProps} />}
    {(routeAllowed(WEBPLAYER_SCREEN_LAYOUTS_ASSIGN.path)) && <AuthenticatedRoute path={getPath(WEBPLAYER_SCREEN_LAYOUTS_ASSIGN)} component={WebplayerReassignPlayerToScreenLayout} props={childProps} />}

    {(routeAllowed(SITES_REDIRECT.path)) && <AuthenticatedRoute path={getPath(SITES_REDIRECT)} component={SitesRedirect} props={childProps} />}
    {(routeAllowed(PLAYERS_REDIRECT.path)) && <AuthenticatedRoute path={getPath(PLAYERS_REDIRECT)} component={PlayersRedirect} props={childProps} />}
    {(routeAllowed(DEVICES_REDIRECT.path)) && <AuthenticatedRoute path={getPath(DEVICES_REDIRECT)} component={DevicesRedirect} props={childProps} />}
    {(routeAllowed(APPS_REDIRECT.path)) && <AuthenticatedRoute path={getPath(APPS_REDIRECT)} component={AppsRedirect} props={childProps} />}


    {/* {(routeAllowed(ROUTE_SITE_FACILITIES.path)) && <AuthenticatedRoute path={getPath(ROUTE_SITE_FACILITIES)} component={AsyncSiteFacilities} props={childProps} />} */}

    {(routeAllowed(ROUTE_FUEL_TYPES.path)) && <AuthenticatedRoute path={getPath(ROUTE_FUEL_TYPES)} component={AsyncFuelTypes} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_COMPONENTS.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_COMPONENTS)} component={AsyncCisComponents} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_LINE_ITEM_NEW.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_LINE_ITEM_NEW)} component={AsyncCisLineItemCreation} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_LINE_ITEM_UPDATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_LINE_ITEM_UPDATE)} component={AsyncCisLineItemCreation} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS_NEW.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS_NEW)} component={AsyncCisCampaignsCrud} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS_UPDATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS_UPDATE)} component={AsyncCisCampaignsCrud} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS_V2_EDIT.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS_V2_EDIT)} component={AsyncCisCampaignEditV2} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS_V2_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS_V2_CREATE)} component={AsyncCisCampaignEditV2} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS_VIEW.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS_VIEW)} component={AsyncCisCampaignsView} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CAMPAIGNS.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CAMPAIGNS)} component={AsyncCisCampaigns} props={childProps} />}
    {(routeAllowed(ROUTE_CIS_CREATIVES_NEW.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CREATIVES_NEW)} component={AsyncCisCreativesNew} props={childProps}/>}
    {(routeAllowed(ROUTE_CIS_CREATIVES.path)) && <AuthenticatedRoute path={getPath(ROUTE_CIS_CREATIVES)} component={AsyncCisCreatives} props={childProps}/>}

    {(routeAllowed(ROUTE_SUPPORT_ENOZZLE_RANGE_INDEX.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_ENOZZLE_RANGE_INDEX)} component={EnozzleRangeSettings} props={childProps} />}

    {(routeAllowed(ROUTE_SUPPORT_TICKET_CREATE.path)) && <AuthenticatedRoute path={getPath(ROUTE_SUPPORT_TICKET_CREATE)} component={CreateTicket} props={childProps} />}
    {(routeAllowed(ROUTE_DATA_VEHICLE_DASHBOARD.path)) && <AuthenticatedRoute path={getPath(ROUTE_DATA_VEHICLE_DASHBOARD)} component={VehicleDashboard} props={childProps} />}
    { /* Finally, catch all unmatched routes */ }

    {isUserAuthenticated() ? <Route component={NotFound}/> : <Redirect to="/" />}

  </Switch>)

export default routes

routes.propTypes = {
  childProps: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
}
